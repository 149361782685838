import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../../lib/backend/data"
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import Skeleton from "react-loading-skeleton"
import { cerrarSesion } from "../../../redux/actions/session"
import { Button, ButtonGroup, Col, Row, Spinner, Table } from "react-bootstrap"
import { debounce } from "@mui/material"
import { FaCheckCircle, FaRegClock } from "react-icons/fa"
import { MdUpdateDisabled } from "react-icons/md"
import { GrUpdate } from "react-icons/gr"

const PickingOrden = (props) => {
    const { viewType, id_orden, title } = props
    const [ campos, setCampos ] = useState([])
    const [ guardado, setGuardado ] = useState(true)
    const [ loadingPicking, setLoadingPicking ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ loadingGuardando, setLoadingGuardando ] = useState(false)
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)

    useEffect(() => {
        obtenerDatos()
    }, [])
    const obtenerDatos = async () => {
        console.log("ID_orden:", id_orden)
        if(!id_orden) return setLoading(false)
        setLoading(true)
        return fetch(`${urlapi}/ordenes/picking`,{
            method:'POST',
            body: JSON.stringify({
                id_orden
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if(Array.isArray(res?.productos) !== false){
                setCampos(res?.productos)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const actualizar = useCallback(debounce((data) => guardarCambios(data), 500), []);

    const handleChange = (e) => {
        const { name, value } = e.target
        const id = name
        return setCampos(prev => {
            let actual = [...prev]
            const i = actual.findIndex(campo => campo._id === id)
            if(i > -1 ) actual[i].value = value
            actualizar(actual)
            return actual
        })
    }
    const eliminar = (pos) => {
        if(props.onDelete) props.onDelete(pos)
    }

    const reiniciarPickeo = () => {
        return setCampos(prev => {
            let actual = [...prev].map(campo => ({ ...campo, cantidad_pickeada: 0 }))
            setTimeout(() => guardarCambios(actual), 500)
            return actual
        })
    }

    const guardarCambios = async (data) => {
        setGuardado(false)
        setLoadingPicking(true)
        return fetch(`${urlapi}/ordenes/picking`,{
            method:'PUT',
            body: JSON.stringify({
                productos: data
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res.success){
                setGuardado(true)
                if(props.onSave) props.onSave({ id_orden, productos: data })
            }
            return setLoadingPicking(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingPicking(false)
        })
    }

    const renderizarSegunTipo = (campo) => {

        if(campo.type === "select" )
            return <div>
                <select className="form-control d-block" name={campo._id} onChange={handleChange} defaultValue={campo.value} >
                    <option value="">Seleccione</option>
                    {
                        campo.options.map((opt, i) => {
                            return <option key={i} value={opt.value}>{opt.value}</option>
                        })
                    }
                </select>
            </div>
        return <div>
            <input className="form-control shadow-sm mb-2" name={campo._id} onChange={handleChange} defaultValue={campo.value}  />
        </div>
    }

    const handleChangePicking = (e, id) => {
        setGuardado(false)
        const { value } = e.target
        setCampos(prev => {
            let actual = [...prev]
            const i = actual.findIndex(campo => campo._id === id)
            if(i > -1 ){
                const cantidad = parseInt(actual[i].quantity)
                const valor = parseInt(value)
                actual[i].cantidad_pickeada = valor
                actual[i].picked = cantidad >= valor
            }
            actualizar(actual)
            return [ ...[], ...actual]
        })
    }

    const mostrarCampos = () => {
        if(loading) return <Skeleton height={50} />

        if(viewType === "simple") return <div className="table-responsive">
            <Table bordered >
                <thead>
                    <tr>
                        <th>NOMBRE</th>
                        <th>VALOR</th>
                    </tr>
                </thead>
                <tbody>
                {
                    campos.map(campo => {
                        if(!campo.value) return false
                        return <tr md={3} key={campo._id}>
                            <th>{campo.nombre}</th>
                            <th>{campo.value}</th>
                        </tr>
                    })
                }
                </tbody>
            </Table>
        </div>

        let titulo_boton = loadingPicking ? "GUARDANDO...": "GUARDAR"
        if(guardado) titulo_boton = "GUARDADO"
        return <div>
            {/* { loadingGuardando ? <h5><Spinner animation="border" /> Guardando...</h5> : <h5 className="text-success"><b>Guardado</b></h5> } */}

            <table className="table table-bordered">
                <thead>
                    <tr>
                        <td>Nombre</td>
                        <td>Códido</td>
                        <td>Cantidad</td>
                        <td>Cantidad pickeada</td>
                        <td>Pickeo</td>
                    </tr>
                </thead>
                <tbody>
                {
                    campos.map(campo => {
                        return <tr key={campo._id}>
                            <td>{campo.description}</td>
                            <td>{campo.codigo_item}</td>
                            <td><h2>{campo.quantity}</h2></td>
                            <td><input type="number" value={campo.cantidad_pickeada} max={campo.quantity} className="form-control" onChange={(e) => handleChangePicking(e, campo._id)} /></td>
                            <td>{ campo.quantity.toString() === campo.cantidad_pickeada.toString() ? <FaCheckCircle size={40} color="green" /> : <FaRegClock size={40} /> }</td>
                        </tr>
                    })
                }
                </tbody>
            </table>
            <ButtonGroup>
            <Button variant="success" disabled={loadingPicking || guardado} onClick={() => guardarCambios(campos)} >{titulo_boton}</Button>
            <Button variant="secondary" onClick={() => reiniciarPickeo()} ><GrUpdate color="white" /> REINICIAR PICKING</Button>
            </ButtonGroup>
            {/* { loadingGuardando ? <Spinner animation="border" /> : <Button size="sm" className='shadow-sm w-100' variant="primary" onClick={() => guardarCambios()} ><b>GUARDAR CAMBIOS</b></Button> } */}
        </div>
    }

    return <div>
        {mostrarCampos()}
    </div>
}

export default PickingOrden