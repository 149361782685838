import { useEffect, useState } from 'react'
import data from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../redux/actions/session';

const WhatsappPlantillaSelector = (props) => {
    const {
        onLoad,
        defaultValue
    } = props
    const default_value = defaultValue ? defaultValue : ''
    const [ tiposServicio, setTiposServicio ] = useState([])
    const [ options, setOptions ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const pais = useSelector(state => state.pais)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const dispatch = useDispatch()

    const obtenerProveedores = async ()=>{
        setLoading(true)
        return fetch(`${data.urlapi}/whatsapp/plantillas`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setTiposServicio(res)
                if(onLoad) onLoad(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }
    
    useEffect(() => {
        obtenerProveedores()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (e) => {
        const i = tiposServicio.findIndex(zona => zona._id === e.value)
        console.log({i})
        if(i > -1 ){
            if(props.onChangeValue) props.onChangeValue(tiposServicio[i])
        }
        return false
    }

    let default_option = false
    if(default_value){
        const valor_busqueda = default_value?._id ? default_value?._id : default_value
        const i = tiposServicio.findIndex(ti => ti._id === valor_busqueda)
        if(i > -1) default_option = { value: tiposServicio[i]._id, label: tiposServicio[i].mensaje }
    }

    return <div>
        <Select
            value={default_option}
            placeholder="Selecciona..."
            options={tiposServicio.map(obj => ({ value: obj._id, label: obj.mensaje }))}
            isLoading={loading}
            noOptionsMessage={()=>"Sin opciones"}
            loadingMessage={()=>"Cargando información..."}
            onChange={handleChange}
            className="mb-2"
        />
    </div>

}

export default WhatsappPlantillaSelector