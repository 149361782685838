import { useDispatch, useSelector } from "react-redux"
import data, { timezone, urlapi } from "../../../lib/backend/data"
import { useEffect, useState } from "react"
import CargandoTabla from "./cargando"
import { calcularPorcentaje, duracionTiempoString, formatYMD } from "../../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { rutas } from "../../../lib/routes/routes"
import { formatDateHoy, obtenerFechaHoraZonaHorariaLocal } from "../../../lib/helpers/dates"
import { BsBoxArrowInUpRight } from "react-icons/bs"
import { DateTime } from "luxon"
import { Button, ButtonGroup, Card, Col, OverlayTrigger, Pagination, Row, Spinner, Table, Tooltip } from "react-bootstrap"
import { cerrarSesion } from "../../../redux/actions/session"
import { url_images } from '../../../lib/global/data'
import LoadingFlota from "../../../subComponents/general/loadingAnimations/loadingFlota"
import { RxAvatar } from "react-icons/rx"


const ListadoConductoresRastreo = (props) => {
    const {
        condicion_default,
        tableSize,
        button_text,
        fecha,
        hideExporter,
        hideSearch,
        tituloHoverDriver,
        hideHeader,
        typeView
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [loadingExcel, setLoadingExcel] = useState(false)
    const ayer = DateTime.now().setZone(timezone).minus({ day: 1 }).toJSDate().toISOString()
    const [fechaConsulta, setFechaConsulta] = useState(formatYMD(ayer))
    const [filtros, setFiltros] = useState([])
    const [intervalo, setIntervalo] = useState(false)
    const [loadingConductores, setLoadingConductores] = useState(true)
    const [loadingCoordenadas, setLoadingCoordenadas] = useState(false)
    const [conductorSeleccionado, setConductorSeleccionado] = useState(false)
    const [pagina, setPagina] = useState(1)
    const [campoBusqueda, setCampoBusqueda] = useState('')
    const [total, setTotal] = useState(0)
    const [condicion_busqueda, setCondicionBusqueda] = useState(condicion_default ? condicion_default : {})
    const trash = props.trash ? props.trash : false
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [isFiltered, setIsFiltered] = useState(false)
    const tipo = props.tipo ? props.tipo : "link"
    const titulo = props.title ? props.title : 'registros'
    let titulo_tooltip = tituloHoverDriver ? tituloHoverDriver : "Toca para ver en el mapa"
    let titulo_button = button_text ? button_text : ""

    const obtenerConductores = async (page, query, fecha_consulta) => {
        setLoadingConductores(true)
        if (query) if (trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        setCondicionBusqueda(condicion)
        const fecha = fecha_consulta ? fecha_consulta : fechaConsulta
        return fetch(`${urlapi}/informes/conductores/tracking/list`, {
            method: 'POST',
            body: JSON.stringify({
                condicion,
                pagina: page,
                fecha
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
            .then(res => {
                if (res.status === 401) return dispatch(cerrarSesion())
                return res.json()
            })
            .then(res => {
                if (!res) {
                    // messageApi.error('Sin datos')
                    return setLoadingConductores(false)
                } else if (res.errorMessage) {
                    // messageApi.error(res.errorMessage)
                    return setLoadingConductores(false)
                }
                if (Array.isArray(res.datos) !== false) {
                    setConductores(res.datos)
                    if (conductorSeleccionado) {
                        const i = res.datos.findIndex(con => con._id === conductorSeleccionado._id)
                        seleccionarRegistro(res.datos[i])
                    }
                    setTotal(res.total)
                    if (res.filtros) setFiltros(res.filtros)
                }
                return setLoadingConductores(false)
            })
            .catch(error => {
                // messageApi.error("Error al consultar la información, intente nuevamente")
                return setLoadingConductores(false)
            })
    }

    useEffect(() => {
        obtenerConductores(1, false)
    }, [])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerConductores(e, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if (!cantidad) return false
        return <div className="mb-3">
            <Pagination showSizeChanger={false} current={pagina} total={(cantidad * 10)} onChange={handleChangePagina} />
        </div>
    }

    const seleccionarRegistro = data => {
        if (props.onSelect) props.onSelect(data)
        setConductorSeleccionado(data)
        if (!data) return false
        data.fecha = fechaConsulta
        var divElement = document.getElementById("box_conductores");
        divElement.scrollTo({
            top: 0,
            behavior: "smooth" // Agrega una animación suave
        });
    }

    const showByType = (tipo, data) => {

        const link = <a target='_blank' href={`${rutas.vehiculos.slug}/edit/${data._id}`}>
        <OverlayTrigger
            key={'right'}
            placement={'right'}
            overlay={
                <Tooltip id={'right'}>
                    Editar
                </Tooltip>
            }>
            <BsBoxArrowInUpRight />
            </OverlayTrigger></a>

        switch (tipo) {
            case "funcion":
                if(titulo_button){
                    return <div>
                        <b className="hover" >{data.nombres} {data.apellido_p ? data.apellido_p : ''} {link}</b>
                        <Button size="sm" className="mt-1" style={{ fontSize: 12 }} variant="outline-primary" onClick={() => seleccionarRegistro(data)}>{titulo_button}</Button>
                    </div>
                }
                return <b className="hover" onClick={() => seleccionarRegistro(data)}>{data.nombres} {data.apellido_p ? data.apellido_p : ''} {link}</b>
            default:
                return <Link to={`${rutas.drivers.slug}/${data._id}`}>{data.nombres} {data.apellido_p ? data.apellido_p : ''}</Link>
        }
    }

    const filtrarRegistros = (filtro) => {
        if (!filtro.condicion) return false
        if (typeof filtro.condicion !== "object") return false
        obtenerConductores(1, filtro.condicion)
    }

    const header = () => {
        if (hideHeader === true) return false
        return <Row gutter={15}>
            {
                filtros.map((filtro, i) => {


                    const porcentaje = calcularPorcentaje(total, filtro.cantidad)
                    return <Col className="hover" md={3} key={`filtro-${i}`} onClick={() => filtrarRegistros(filtro)} >
                        <Card size="small" className="mb-3">
                            <p level={3} style={{ fontSize: 12 }} className="m-0">{filtro.label}
                                {
                                    filtro.help_text ? <Tooltip title={filtro.help_text}>
                                        {/* <QuestionCircleOutlined style={{ marginLeft: 5 }} /> */}
                                    </Tooltip> : false
                                }
                            </p>
                            <Row>
                                <Col xs={12}><h3 className="m-0">{filtro.cantidad}</h3></Col>
                                <Col xs={12} style={{ textAlign: "right", marginTop: 8 }}> <Spinner size={35} type="circle" percent={porcentaje} /></Col>
                            </Row>
                        </Card>
                    </Col>
                })
            }
        </Row>
    }

    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/drivers`, {
            method: 'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
            .then(res => {
                if (res.status === 401) return dispatch(cerrarSesion())
                return res.blob()
            })
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                const fecha = obtenerFechaHoraZonaHorariaLocal()
                a.download = `reporte-${fecha}.xlsx`;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
                return setLoadingExcel(false)
            })
            .catch(error => {
                // messageApi.error("Error al consultar la información, intente nuevamente")
                return setLoadingExcel(false)
            })
    }

    const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
    };

    const reiniciarIntervalo = (pagina, cond) => {
        clearInterval(intervalo)
        const id_intervalo = setInterval(() => {
            obtenerConductores(pagina, cond, false)
        }, 4000);
        setIntervalo(id_intervalo)
    }


    const handleSubmit = (e) => {
        if (e) e.preventDefault()
        if (!campoBusqueda) {
            setIsFiltered(false)
            setCondicionBusqueda(condicion_default);
            setPagina(1);
            return obtenerConductores(1, condicion_default)
        }
        setIsFiltered(true)
        const condicion = {
            ...condicion_default,
            $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerConductores(1, condicion);
    };

    const buscador = () => {
        if (hideSearch === true) return false
        return false
        /*
        return <Form onSubmitCapture={handleSubmit}>
        <Row gutter={15}>
            <Col md={10}>
                <Form.Item label="Buscar">
                    <Input autoFocus defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Nombre, apellido, email, teléfono o ${nombreDNIPorPais(pais)}`} />
                </Form.Item>
            </Col>
            <Col md={14}>
                <Form.Item>
                    <ButtonGroup style={{ width: "100%"}}>
                    <Button onClick={handleSubmit} type="primary" > BUSCAR</Button>
                    <Button disabled={!isFiltered} onClick={() => {
                        setIsFiltered(false)
                        obtenerConductores(1, {})
                        setCampoBusqueda('')
                        }} > REINICIAR BÚSQUEDA</Button>
                    </ButtonGroup>
                </Form.Item>
            </Col>
        </Row>
               
                </Form>
                */
    }

    const mostrarExportador = () => {
        if (hideExporter === true) return false
        return <div><Button loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green" type="primary" size="small" onClick={() => descargarExcel()}>EXPORTAR</Button></div>
    }

    const mostrarInfoVehiculo = (veh) => {
        if (!veh) return false
        if (typeof veh !== "object") return false
        let modelo = ''
        let marca = ''

        if (typeof veh.modelo === "object") if (veh.modelo.titulo) modelo = veh.modelo.titulo
        if (typeof veh.marca === "object") if (veh.marca.titulo) marca = veh.marca.titulo

        return <div>{marca} {modelo} {veh.patente} <a target='_blank' href={`${rutas.vehiculos.slug}/${veh._id}`}><BsBoxArrowInUpRight /></a></div>
    }

    const handleChangeDate = (e) => {
        const { value } = e.target
        setFechaConsulta(value)
        obtenerConductores(1, false, value)
    }

    const mostrarSeleccionado = () => {
        if (!conductorSeleccionado) return false

        return <Card size="small" className='shadow-sm mb-3' style={{ marginTop: 20 }} >
            <Row className="p-2">
                <Col md={10} xs={10} className="text-primary">
                    <p className="mb-0" style={{ fontWeight: 700 }}>Fecha de análisis <b>{conductorSeleccionado.fecha}</b></p>
                    <h3 level={4} className="mb-0 mt-0" style={{ textTransform: 'uppercase', fontWeight: 900 }}>{conductorSeleccionado.nombres} {conductorSeleccionado.apellido_p} {conductorSeleccionado.apellido_m}
                        <OverlayTrigger
                            key={'right'}
                            placement={'right'}
                            overlay={
                                <Tooltip id={'right'}>
                                    Editar
                                </Tooltip>
                            }>
                            <Link target="_blank" to={`${rutas.vehiculos.slug}/${conductorSeleccionado._id}`} style={{ fontSize: 18 }}><BsBoxArrowInUpRight /></Link>
                        </OverlayTrigger></h3>
                    <p className="mb-0" style={{ fontWeight: 700 }}> {conductorSeleccionado.phone}</p>
                </Col>
                <Col md={2} xs={2} style={{ textAlign: "right" }}>
                    <Button className='p-0 bg-white border-0' onClick={() => seleccionarRegistro(false)}><i class="fa-solid fa-xmark text-danger"></i></Button>
                </Col>
            </Row>
        </Card >
    }

    const formatTime = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = Math.round(minutes % 60);

        if (hours === 0 && remainingMinutes === 0) {
            return "Sin datos";
        } else if (hours === 0 && remainingMinutes < 1) {
            return "Menos de 1 Min.";
        } else if (hours === 0) {
            return `${remainingMinutes} Min.`;
        } else if (remainingMinutes === 0) {
            return `${hours} Hrs.`;
        } else {
            return `${hours} Hrs. ${remainingMinutes} Min.`;
        }
    }
    const obtenerDatoInforme = (inf, key, default_value) => {
        const ifnot = typeof default_value !== "undefined" ? default_value : false
        if (!inf) return ifnot
        if (!key) return ifnot
        if (typeof inf !== "object") return ifnot
        if (typeof inf[key] === "undefined") return ifnot
        return inf[key]
    }

    const mostrarDatos = () => {
        if (loadingConductores) return <LoadingFlota />

        return <div>
            {mostrarSeleccionado()}
            <h3 className="text-primary m-0" style={{ fontWeight: 900 }}>{total} en total</h3>
            {mostrarExportador()}
            {buscador()}
            {paginacion(data.pagina, total)}

            <div className="table-responsive rounded">
                <Table className="border shadow-sm">
                    <thead>
                        <tr className="text-white bg-primary " style={{ fontWeight: 700, fontSize: 16 }}>
                            <td>Perfil</td>
                            <td>Nombre y apellidos</td>
                            <td>Fecha</td>
                            <td>Tiempo de conexión</td>
                            <td>Paradas</td>
                            <td>Distancia recorrida</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            conductores.map((data) => {
                                let fecha = obtenerDatoInforme(data.informe, "fecha", fechaConsulta)
                                let paradas = obtenerDatoInforme(data.informe, "paradas", 0)
                                let numero = obtenerDatoInforme(data.informe, "minutos_conexion", "0 Min")
                                let steps = 'Sin datos'
                                if (paradas) if (Array.isArray(paradas) !== false) steps = paradas.length

                                let km = obtenerDatoInforme(data.informe, "distancia_km_recorrida")
                                if (!isNaN(parseFloat(km))) km = parseFloat(km).toFixed(2)
                                let validar_distancia = km ? km : 0
                                if (validar_distancia < 1 && validar_distancia > 0) validar_distancia = "Menos de 1"

                                return <tr key={data._id} className="text-primary">
                                    <td>
                                        { data.image_profile ? <img src={data.image_profile} style={{ width: "100%" }} /> : <RxAvatar size={40} /> }
                                    </td>
                                    <td style={{ textTransform: 'uppercase' }}>{showByType(typeView, data)}</td>
                                    <td>{fecha}</td>
                                    <td>{duracionTiempoString(numero)}</td>
                                    <td>{steps}</td>
                                    <td>{validar_distancia === 0 ? "Sin datos" : `${validar_distancia} Km.`}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    }
    const mostrarRegistros = () => {
        return <div className="">
            {header()}

            <Row className="mb-3 align-items-end">
                <Col>
                    <label className="form-control-label d-block text-primary" style={{ fontWeight: 700 }}>Fecha de revisión</label>
                    <input className="form-control shadow-sm" disabled={loadingConductores} defaultValue={fechaConsulta} type="date" onChange={handleChangeDate} />
                </Col>
                <Col >
                </Col>

            </Row>

            {mostrarDatos()}
        </div>
    }

    return <div>
        {mostrarRegistros()}
    </div>
}
export default ListadoConductoresRastreo
