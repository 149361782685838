import { useEffect, useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { BsLayoutThreeColumns } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { modificarEsquema } from '../../redux/actions/esquemaTablas'
import { ReactSortable } from "react-sortablejs";
import { Esquema } from "../../lib/esquemas/tablas"

const PersonalizarColumnas = (props) => {
    const {
        screen
    } = props
    const esquema_tablas = useSelector(state => state.esquema_tablas)
    const [ esquemaInicial, setEsquemaInicial ] = useState("")
    const comparar = screen === "ordenes" ? Esquema.ORDENES.map(e => ({...e, active: false})) : []
    const actuales = esquema_tablas[screen]
    const [ esquemaState, setEsquemaState ] = useState([ ...actuales, ...comparar.filter(e => !actuales.map(sh => sh.key).includes(e.key) ) ])
    const [ pendingSave, setPendingSave ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()

    useEffect(() => {
        setEsquemaInicial(esquema_tablas[screen])
    }, [])

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChange = (e, column) => {
        const instancia_esquema = JSON.parse( JSON.stringify(esquema_tablas) )
        const i = instancia_esquema[screen].findIndex(e => e.key === column.key)
        if(i > -1){
            instancia_esquema[screen][i].active = e.target.checked
        } else {
            instancia_esquema[screen].push({
                ...column,
                active: e.target.checked
            })
        }
        return dispatch(modificarEsquema(instancia_esquema, session.tokenSession))
    }

    const refrescarOrden = () => {
        setPendingSave(false)
        const ordenNuevo = esquemaState.map(e => {
            const pos = esquema_tablas[screen].findIndex(info => info.key === e.key);
            if (pos > -1){
                e.active = esquema_tablas[screen][pos].active === true ? true : false
            }
            return e
        })
        const instancia_esquema = JSON.parse( JSON.stringify(esquema_tablas) )
        instancia_esquema[screen] = ordenNuevo
        setEsquemaInicial(ordenNuevo)
        dispatch(modificarEsquema(instancia_esquema, session.tokenSession))
        setOpenModal(false)

    }
    const handleSort = (newList) => {
        setEsquemaState(newList)
        setPendingSave(true)
    }
    return <div>
        <Button size="sm" className='w-100' variant='light' onClick={() => setOpenModal(true)} ><BsLayoutThreeColumns /> COLUMNAS</Button>
        <Modal show={openModal}  onHide={handleClose}
        centered
        >
        <Modal.Header closeButton className="pb-0">
        <div>
          <Modal.Title>Personaliza las columnas que deseas visualizar</Modal.Title>
          <p>Puedes mover los elementos para cambiar su posicion</p>
          </div>
        </Modal.Header>
        <Modal.Body >
            <div style={{ maxHeight: "50vh", overflowY: "scroll" }}>
            { JSON.stringify(esquemaInicial) !== JSON.stringify(esquemaState) ? <p><b>Guarda tus cambios</b></p> : <p className="text-success"><b>Guardado</b></p> }
            <ReactSortable list={esquemaState} setList={(d) => handleSort(d)}>
                          {esquemaState.map((column, i) => {
                              let checked = false;
                            const pos = esquema_tablas[screen].findIndex(e => e.key === column.key);
                            if (pos > -1) checked = esquema_tablas[screen][pos].active === true ? true : false;
                            return (
                                <Col key={`column-${i}`} className="mb-3">
                                <Form.Group className="mb-0" controlId={column.key}>
                                  <div className="handle hover d-flex bg-light font align-items-center border px-2 shadow-sm"
                                  style={{fontSize:15}}>
                                    <i className="fa-solid fa-arrows-up-down-left-right mr-1  text-secondary"></i>
                                    <Form.Check 
                                      type="switch" 
                                      checked={checked} 
                                      name={column.key} 
                                      label={column.label} 
                                      onChange={(e) => handleChange(e, column)} />
                                  </div>
                                </Form.Group>
                              </Col>
                            );
                        })}
                        </ReactSortable>
                        </div>
            <div className="d-flex justify-content-end">
            <Button variant="success" className="mt-3 w-100" onClick={() => refrescarOrden()}>GUARDAR</Button>
            </div>
        </Modal.Body>
        
      </Modal>
    </div>
}

export default PersonalizarColumnas